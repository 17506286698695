<template>
  <AppRow permission="UNDEFINED">
    <v-col>
      <v-alert
        outlined
        type="error"
      >
        Этот раздел отключен!
      </v-alert>
    </v-col>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';

export default {
  components: {
    AppRow,
  },
};
</script>
